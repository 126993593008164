<template>
    <section>
        <PageHeader>
            <h1>Components</h1>
        </PageHeader>

        <div class="container flex flex-col gap-4 py-8">
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/alert.html">Alert</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <div>
                    <Alert class="w-96">
                        <AlertDescription>This is just letting you know.</AlertDescription>
                    </Alert>
                </div>
                <Alert variant="danger" class="w-96">
                    <AlertTitle>Uh Oh, Danger</AlertTitle>
                    <AlertDescription>You need to do something.</AlertDescription>
                </Alert>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/badge.html">Badge</a>
            </h2>
            <div class="flex flex-wrap gap-4 items-start">
                <div v-for="variant in badgeVariants" :key="variant" class="flex flex-col gap-4">
                    <Badge v-for="fill in badgeFills" :key="fill" :variant="variant" :fill="fill">
                        {{ variant + " " + fill }}
                    </Badge>
                </div>
            </div>
            <div class="flex flex-wrap gap-4">
                <div class="flex">
                    <Badge variant="outline" class="border-r-0 rounded-r-none">Badge with label</Badge>
                    <Badge variant="accent" class="rounded-l-none">Value</Badge>
                </div>
                <Badge>
                    <Icon name="check" />
                    <span>With icons</span>
                    <Icon name="xmark" />
                </Badge>
            </div>
            <div class="flex gap-4">
                <PartLifecycleRisk v-for="risk in 4" :key="risk" :risk="risk - 1" hide-help />
            </div>
            <div class="flex gap-4">
                <PartSupplyChainRisk v-for="risk in 4" :key="risk" :risk="risk - 1" hide-help />
            </div>

            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/button.html">Button</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <div v-for="variant in buttonVariants" :key="variant" class="flex flex-col gap-4">
                    <Button :variant="variant"> Enabled {{ variant }} </Button>
                    <Button :variant="variant" fill="solid"> Solid {{ variant }} </Button>
                    <Button :variant="variant" disabled> Disabled {{ variant }} </Button>
                </div>
            </div>
            <div class="flex flex-wrap gap-4">
                <Button>Default size</Button>
                <Button v-for="size in buttonSizes" :key="size" :size="size"> Size {{ size }} </Button>
            </div>
            <div class="flex flex-wrap gap-4">
                <Button class="rounded-none">rounded-none</Button>
                <Button class="rounded-lg">rounded-lg</Button>
                <Button class="rounded-xl">rounded-xl</Button>
                <Button class="rounded-2xl">rounded-2xl</Button>
                <Button class="rounded-full">rounded-full</Button>
            </div>
            <div class="flex flex-wrap gap-4">
                <Button as="a" href="#">Link</Button>
                <Button>
                    <Icon name="check" />
                    <span>With icon</span>
                </Button>
                <Button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4">
                        <path
                            d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z"></path>
                        <path
                            fill-rule="evenodd"
                            d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>Custom SVG</span>
                </Button>
                <Button>
                    <Icon name="chevron-left" />
                    <span>Icons Before and After</span>
                    <Icon name="chevron-right" />
                </Button>
            </div>
            <h2 class="m-0">Button Group</h2>
            <div class="flex flex-wrap gap-4">
                <ButtonGroup>
                    <Button
                        v-for="opt in buttonOptions"
                        :key="opt"
                        :fill="selectedButton == opt ? 'solid' : 'none'"
                        @click="selectedButton = opt">
                        {{ opt }}
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        v-for="opt in buttonOptions"
                        :key="opt"
                        :variant="selectedButton == opt ? 'accent' : 'primary'"
                        fill="solid"
                        size="lg"
                        class="rounded-full"
                        @click="selectedButton = opt">
                        {{ opt }}
                    </Button>
                </ButtonGroup>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/checkbox.html">Checkbox</a>
            </h2>
            <div class="flex gap-8">
                <fieldset class="flex items-center space-x-2">
                    <Checkbox id="check-1" v-model="isCheckboxChecked" />
                    <label for="check-1">Checkbox with label</label>
                </fieldset>
                <fieldset class="flex items-center space-x-3 text-lg">
                    <Checkbox id="check-2" v-model="isCheckboxChecked" class="size-6" />
                    <label for="check-2">Checkbox large</label>
                </fieldset>
                <fieldset class="flex items-center space-x-1 text-xs">
                    <Checkbox id="check-3" v-model="isCheckboxChecked" class="size-3" />
                    <label for="check-3">Checkbox small</label>
                </fieldset>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/command.html">Command</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <Command v-model="commandSelection" class="w-64 border p-2 rounded shadow">
                    <CommandInput placeholder="Search..." />
                    <CommandList>
                        <CommandEmpty>No items found.</CommandEmpty>
                        <template v-for="(group, index) in commandGroups" :key="group">
                            <CommandSeparator v-if="index > 0" />
                            <CommandGroup>
                                <CommandItem
                                    v-for="item in group"
                                    :key="item"
                                    class="data-[state=checked]:bg-primary data-[state=checked]:text-white"
                                    :value="item">
                                    {{ item }}
                                </CommandItem>
                            </CommandGroup>
                        </template>
                    </CommandList>
                </Command>
                <Popover>
                    <PopoverTrigger as-child>
                        <Button class="group min-w-48 justify-between">
                            <span>{{ commandSelection || "(Combobox)" }}</span>
                            <i class="fass fa-chevron-right group-data-[state=open]:rotate-90 transition-transform"></i>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <Command v-model="commandSelection">
                            <CommandInput placeholder="Search..." />
                            <CommandList>
                                <CommandEmpty>No items found.</CommandEmpty>
                                <template v-for="(group, index) in commandGroups" :key="group">
                                    <CommandSeparator v-if="index > 0" />
                                    <CommandGroup>
                                        <CommandItem
                                            v-for="item in group"
                                            :key="item"
                                            class="data-[state=checked]:bg-primary data-[state=checked]:text-white"
                                            :value="item">
                                            {{ item }}
                                        </CommandItem>
                                    </CommandGroup>
                                </template>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/dialog.html">Dialog</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <Dialog v-model:open="isDialogOpen">
                    <DialogTrigger as-child>
                        <Button>Open Dialog</Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Dialog Title Here</DialogTitle>
                        </DialogHeader>
                        <div>This is the content of the dialog</div>
                        <DialogFooter>
                            <Spinner v-if="isDialogSaving" />
                            <DialogClose as-child>
                                <Button :disabled="isDialogSaving">Cancel</Button>
                            </DialogClose>
                            <Button variant="primary" fill="solid" :disabled="isDialogSaving" @click="dialogSave">
                                <span>Save</span>
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/dropdown-menu.html">Dropdown Menu</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <DropdownMenu>
                    <DropdownMenuTrigger class="group" data-test-id="region-dropdown-button">
                        Dropdown Menu Trigger
                        <i class="mx-1 inline-block fas fa-caret-down group-data-[state=open]:rotate-180"></i>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent class="w-40">
                        <DropdownMenuLabel>Items</DropdownMenuLabel>
                        <DropdownMenuItem>Item 1</DropdownMenuItem>
                        <DropdownMenuItem>Item 2</DropdownMenuItem>
                        <DropdownMenuItem>Item 3</DropdownMenuItem>
                        <DropdownMenuSub>
                            <DropdownMenuSubTrigger>Sub Items</DropdownMenuSubTrigger>
                            <DropdownMenuPortal>
                                <DropdownMenuSubContent>
                                    <DropdownMenuItem>Sub Item 1</DropdownMenuItem>
                                    <DropdownMenuItem>Sub Item 2</DropdownMenuItem>
                                    <DropdownMenuItem>Sub Item 3</DropdownMenuItem>
                                </DropdownMenuSubContent>
                            </DropdownMenuPortal>
                        </DropdownMenuSub>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <h2 class="m-0">
                <a href="https://fontawesome.com/kits/b097c54e65/icons">Icons</a>
            </h2>
            <div class="flex flex-wrap gap-2">
                <Badge v-for="name in ICON_NAMES" :key="name" variant="outline">
                    <Icon :name />
                    <span>{{ name }}</span>
                </Badge>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/popover.html">Popover</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <Popover>
                    <PopoverTrigger>Popover Trigger</PopoverTrigger>
                    <PopoverContent>
                        <h4>Content here</h4>
                        <p>This is whatever you want it to be.</p>
                        <p>See the Command component for how to make a combobox.</p>
                    </PopoverContent>
                </Popover>
                <Popover>
                    <PopoverTrigger>
                        <Badge variant="warning">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="size-4"
                                fill="currentColor"
                                viewBox="0 0 512 512">
                                <path
                                    d="M256 480c16.7 0 40.4-14.4 61.9-57.3c9.9-19.8 18.2-43.7 24.1-70.7l-172 0c5.9 27 14.2 50.9 24.1 70.7C215.6 465.6 239.3 480 256 480zM164.3 320l183.4 0c2.8-20.2 4.3-41.7 4.3-64s-1.5-43.8-4.3-64l-183.4 0c-2.8 20.2-4.3 41.7-4.3 64s1.5 43.8 4.3 64zM170 160l172 0c-5.9-27-14.2-50.9-24.1-70.7C296.4 46.4 272.7 32 256 32s-40.4 14.4-61.9 57.3C184.2 109.1 175.9 133 170 160zm210 32c2.6 20.5 4 41.9 4 64s-1.4 43.5-4 64l90.8 0c6-20.3 9.3-41.8 9.3-64s-3.2-43.7-9.3-64L380 192zm78.5-32c-25.9-54.5-73.1-96.9-130.9-116.3c21 28.3 37.6 68.8 47.2 116.3l83.8 0zm-321.1 0c9.6-47.6 26.2-88 47.2-116.3C126.7 63.1 79.4 105.5 53.6 160l83.7 0zm-96 32c-6 20.3-9.3 41.8-9.3 64s3.2 43.7 9.3 64l90.8 0c-2.6-20.5-4-41.9-4-64s1.4-43.5 4-64l-90.8 0zM327.5 468.3c57.8-19.5 105-61.8 130.9-116.3l-83.8 0c-9.6 47.6-26.2 88-47.2 116.3zm-143 0c-21-28.3-37.5-68.8-47.2-116.3l-83.7 0c25.9 54.5 73.1 96.9 130.9 116.3zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z" />
                            </svg>
                            <span>Tariff</span>
                        </Badge>
                    </PopoverTrigger>
                    <PopoverContent>
                        <p>
                            Distributors indicate that a United States import tariff may apply to this product. Please
                            check with individual distributors for further details.
                        </p>
                    </PopoverContent>
                </Popover>
            </div>
            <h2 class="m-0">Spinner</h2>
            <div class="flex flex-wrap gap-4">
                <div
                    v-for="variant in spinnerVariants"
                    :key="variant"
                    class="p-2"
                    :class="{ 'bg-gray-300': variant == 'white' }">
                    <div>{{ variant }}</div>
                    <Spinner :variant="variant" />
                </div>
            </div>
            <div class="flex flex-wrap gap-8">
                <div v-for="size in spinnerSizes" :key="size" class="text-center">
                    <div>{{ size }}</div>
                    <Spinner :size="size" />
                </div>
            </div>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/tabs.html">Tabs</a>
            </h2>
            <Tabs class="max-w-screen-xs">
                <TabsList default-value="first">
                    <TabsTrigger value="first">First</TabsTrigger>
                    <TabsTrigger value="second">Second</TabsTrigger>
                    <TabsTrigger value="third">Third</TabsTrigger>
                </TabsList>
                <TabsContent value="first">
                    <div>First tab content</div>
                </TabsContent>
                <TabsContent value="second">
                    <div>Second tab content</div>
                </TabsContent>
                <TabsContent value="third">
                    <div>Third tab content</div>
                </TabsContent>
            </Tabs>
            <h2 class="m-0">
                <a href="https://www.shadcn-vue.com/docs/components/toast.html">Toast</a>
            </h2>
            <div class="flex flex-wrap gap-4">
                <Button
                    v-for="variant in toastVariants"
                    :key="variant"
                    @click="toast({ title: 'Toast', variant, description: 'This is ' + variant })">
                    Toast {{ variant }}
                </Button>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import type { ButtonVariants, BadgeVariants, SpinnerVariants, ToastVariants } from "~/components/ui";
import { ICON_NAMES } from "~/components/ui/Icon.vue";

const globalConfig = useStateGlobalConfig();
if (!globalConfig.value.Features.TAILWIND_COMPONENTS_PAGE)
    throw showError({ statusCode: 404, message: "Page not found" });

const { toast } = useToast();

const buttonVariants: NonNullable<ButtonVariants["variant"]>[] = [
    "default",
    "accent",
    "link",
    "primary",
    "success",
    "warning",
    "danger",
    "white",
];
const buttonSizes: NonNullable<ButtonVariants["size"]>[] = ["sm", "md", "lg", "xl"];
const badgeVariants: NonNullable<BadgeVariants["variant"]>[] = [
    "default",
    "primary",
    "accent",
    "outline",
    "highlight",
    "success",
    "warning",
    "danger",
];
const badgeFills: NonNullable<BadgeVariants["fill"]>[] = ["dark", "light"];
const buttonOptions = ["one", "two", "three", "four"];
const selectedButton = ref("");
const isCheckboxChecked = ref(false);
const commandGroups = [
    ["Apples", "Strawberries", "Bananas"],
    ["Broccoli", "Kale", "Squash"],
];
const commandSelection = ref("");
const isDialogOpen = ref(false);
const isDialogSaving = ref(false);
const spinnerVariants: NonNullable<SpinnerVariants["variant"]>[] = [
    "accent",
    "primary",
    "success",
    "warning",
    "danger",
    "black",
    "white",
];
const spinnerSizes: NonNullable<SpinnerVariants["size"]>[] = ["xs", "sm", "md", "lg", "xl"];
const toastVariants: NonNullable<ToastVariants["variant"]>[] = ["default", "success", "warning", "danger"];

function dialogSave() {
    isDialogSaving.value = true;
    setTimeout(() => {
        isDialogSaving.value = false;
        isDialogOpen.value = false;
        toast({ title: "Saved successfully", description: "You did it!", variant: "success" });
    }, 1000);
}
</script>
